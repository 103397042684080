<template>
  <b-row>
    <b-col sm="1"></b-col>
    <b-col sm="10">
      <b-card class="card-body m-0 p-0" style="background-color: #d9b3d9">
        <b-navbar toggleable="sm" class="p-0" style="background-color: #d9b3d9">
          <b-navbar-brand href="#">
            <b-link class="brand-logo" href="/">
              <b-img
                :src="require('@/assets/images/avatars/13-small.png')"
                style="width: 40px; height: 40px"
                alt="logo"
                class=""
              ></b-img>
            </b-link>
            <b class="text-primary" style="font-size: 22px">Optimal</b>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse visible id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item>
                <router-link to="/">
                  <b-button variant="primary" style="width: 100%">
                    <feather-icon icon="HomeIcon" class="mr-50" />
                    <span class="align-middle">Beranda</span>
                  </b-button>
                </router-link>
              </b-nav-item>

              <b-nav-item v-if="level == 1 || level == 0">
                <router-link to="/user/bimbel">
                  <b-button variant="primary" style="width: 100%">
                    <feather-icon icon="BookOpenIcon" class="mr-50" />
                    <span class="align-middle">Bimbel</span>
                  </b-button>
                </router-link>
              </b-nav-item>

              <b-nav-item v-if="level == 1 || level == 0">
                <router-link to="/user/tryout">
                  <b-button variant="primary" style="width: 100%">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span class="align-middle">TryOut CBT</span>
                  </b-button>
                </router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/blog">
                  <b-button variant="primary" style="width: 100%">
                    <feather-icon icon="AlignCenterIcon" class="mr-50" />
                    <span class="align-middle">Artikel</span>
                  </b-button>
                </router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/product">
                  <b-button variant="primary" style="width: 100%">
                    <feather-icon icon="BoxIcon" class="mr-50" />
                    <span class="align-middle">Produk</span>
                  </b-button>
                </router-link>
              </b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto" v-if="level == 2">
              <b-nav-item>
                <router-link to="/admin/dashboard" right>
                  <b-button variant="warning" style="width: 100%">
                    <feather-icon icon="UserIcon" class="mr-50" />
                    <span class="align-middle">Akun</span>
                  </b-button>
                </router-link>
              </b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto" v-else-if="level == 1 || level == 0">
              <b-nav-item>
                <router-link to="/user/dashboard" right>
                  <b-button variant="warning" style="width: 100%">
                    <feather-icon icon="UserIcon" class="mr-50" />
                    <span class="align-middle">Akun</span>
                  </b-button>
                </router-link>
              </b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto" v-else>
              <b-nav-item>
                <router-link to="/register" right>
                  <b-button variant="danger" style="width: 100%">
                    <feather-icon icon="UserIcon" class="mr-50" />
                    <span class="align-middle">Daftar</span>
                  </b-button>
                </router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/login" right>
                  <b-button variant="danger" style="width: 100%">
                    <feather-icon icon="LogInIcon" class="mr-50" />
                    <span class="align-middle">Login</span>
                  </b-button>
                </router-link>
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    AppTimeline,
    BCol,
    AppTimelineItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data: () => ({
    selected: "left",
    selected2: "right",
    level: localStorage.getItem("Ulevel"),
    fituractivities: [],
    getUrl: "",
  }),
};
</script>